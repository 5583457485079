import React from 'react'
import Layout from 'src/components/Layout'
import { PageContext } from 'src/page/types'
import Video from 'src/components/Video'

const ImageDetectionVideoPage = ({
    pageContext
}: {
    pageContext: PageContext
}) => {
    return (
        <Layout pageCtx={pageContext}>
            <Video
                src={
                    'https://api.garwan.com/docs/image-detection/2018052_image_detection.mp4'
                }
                className={''}
            />
        </Layout>
    )
}

export default ImageDetectionVideoPage
